(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/product-header/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/lb-ui/product-header/assets/javascripts/constants.js');
"use strict";


const supportedBrandings = ['bingo', 'bomben', 'casino', 'live-casino', 'challenge', 'em-tipset', 'em-tipset-europa', 'europatipset', 'fulltraff', 'maltipset', 'matchen', 'oddset', 'poker', 'powerplay', 'stryktipset', 'topptipset', 'hastar', 'hastar-secondary', 'trav-galopp', 'trav-galopp-secondary', 'vm-tipset', 'vm-tipset-europa'];

if (svs.isServer) {
  module.exports = {
    supportedBrandings
  };
} else {
  setGlobal('svs.components.lbUi.productHeader.constants', {
    supportedBrandings
  });
}

 })(window);